/* eslint-disable no-param-reassign */
import Keycloak from 'keycloak-js';
import { type TOption } from '@intellecteu/common-ui';
import { createStore } from '../utils/createStore';
import { ValidiumResponse as ValidiumDto } from '../openapi';

export type SelectValidiumDto = ValidiumDto & TOption;

export type State = {
  isAuthorized?: boolean;
  user: {
    email?: string;
    username?: string;
  } | null;
  selectedValidium: SelectValidiumDto | null;
  login?: () => void;
  logout?: () => void;
};

type Actions = {
  setAuthorization: (v: Keycloak) => void;
  // setAuthorization: (v: {
  //     authenticated: boolean;
  //     tokenParsed: { email: string; preferred_username: string };
  // }) => void;
  setSelectedValidium: (v: SelectValidiumDto | null) => void;
};

export const initialState: State = {
  isAuthorized: false,
  user: null,
  selectedValidium: null,
};

export const useStore = createStore<State, Actions>(initialState, (set) => ({
  selectedValidium: null,
  setAuthorization: ({ authenticated, tokenParsed }) =>
    set((state) => {
      state.isAuthorized = authenticated;
      state.user = {
        email: tokenParsed?.email,
        username: tokenParsed?.preferred_username,
      };
      return state;
    }),
  setSelectedValidium: (value) =>
    set((draft) => {
      draft.selectedValidium = value
        ? {
            ...value,
          }
        : null;
      return draft;
    }),
}));

export { apiStore } from './api';
