import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Icon, IconButton } from '@intellecteu/common-ui';
import s from './styles.module.scss';

const CloseButton = ({ closeToast }: { closeToast?: () => any }) => (
  <IconButton onClick={closeToast} className={s.closeButton} iconName={Icon.icons.close} showHover={true} />
);

export const Toast = () => <ToastContainer className={s.notification} closeButton={<CloseButton />} />;
