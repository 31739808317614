/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * List of the corresponding DAC nodes of the Validium
 */
export type DacNodeResponse = {
  /**
   * Name of the the DAC node
   */
  id: string;
  /**
   * The DAC node type, can be INTERNAL or EXTERNAL
   */
  type: DacNodeResponse.type;
  /**
   * Name of the DAC node Key
   */
  keyId: string;
  /**
   * Current phase (state) of the DAC node
   */
  phase: DacNodeResponse.phase;
  /**
   * The HTTP url of the base chain
   */
  baseChainHTTP: string;
  /**
   * The WS url of the base chain
   */
  baseChainWS: string;
};
export namespace DacNodeResponse {
  /**
   * The DAC node type, can be INTERNAL or EXTERNAL
   */
  export enum type {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
  }
  /**
   * Current phase (state) of the DAC node
   */
  export enum phase {
    RUNNING = 'Running',
    PENDING = 'Pending',
  }
}
