import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const PrivateRoute = ({ children, redirectPath }: { children: ReactNode; redirectPath?: string }) => {
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate(redirectPath ?? '/');
    }
  }, []);

  return children;
};

export default PrivateRoute;
