import { create, StoreApi } from 'zustand';
import { devtools, combine, persist } from 'zustand/middleware';
import { produce } from 'immer';

type StateCreator<T, CustomSetState = StoreApi<T>['setState'], U = T> = (
  set: CustomSetState,
  get: StoreApi<T>['getState'],
  api: StoreApi<T>,
) => U;

const immer =
  <T, U>(config: StateCreator<T, (fn: (draft: T) => void) => void, U>): StateCreator<T, StoreApi<T>['setState'], U> =>
  (set, get, api) =>
    config((fn) => set(produce(fn) as (state: T) => T), get, api);

export const createStore = <PrimaryState extends object, SecondaryState extends object>(
  initialState: PrimaryState,
  config: StateCreator<PrimaryState & SecondaryState, (fn: (draft: PrimaryState) => void) => void, SecondaryState>,
  name = 'store',
) => {
  // update version when you want to introduce changes to store
  return create(
    devtools(
      persist(combine(initialState, immer(config)), {
        name,
        version: 0,
      }),
    ),
  );
};
