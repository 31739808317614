/* eslint-disable no-underscore-dangle */
const BASE_URL = window._env_?.CONFIG_BASEURL || import.meta.env.VITE_BASE_URL;
const POLLING_INTERVAL = window._env_?.POLLING_INTERVAL || import.meta.env.VITE_POLLING_INTERVAL;
const requestPath = (targetQuery: string) => `${BASE_URL}/api/${targetQuery}`;

export const useDataConfig = () => {
  return {
    pollingInterval: Number(POLLING_INTERVAL),
    baseUrl: BASE_URL as string,
    requestPath,
  };
};
