import cx from 'classnames';

import s from './styles.module.scss';

interface IFormControl {
  className?: string;
  component: any;
  disabled?: boolean;
  id: string;
  error?: string;
  [key: string]: any;
}

const FormControl = ({ className, component, error, disabled, ...props }: IFormControl): JSX.Element => {
  const Input = component;

  return (
    <div className={cx(s.formControl, className)}>
      <Input disabled={disabled} {...props} error={error} />

      <div className={cx(s.error, !error && s.errorHidden)}>{error}</div>
    </div>
  );
};

export default FormControl;
