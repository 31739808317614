import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import PrivateRoute from 'app/utils/private-route';

const Layout = loadable(() => import('./layout'));

const App = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default App;
