/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DacNodeResponse } from './DacNodeResponse';
import type { Endpoint } from './Endpoint';
import type { ValidiumContractResponse } from './ValidiumContractResponse';
export type ValidiumResponse = {
  /**
   * Name of the Validium
   */
  validiumId: string;
  /**
   * The HTTP url of the base chain
   */
  baseChainHTTP: string;
  /**
   * The WS url of the base chain
   */
  baseChainWS: string;
  /**
   * The L1 chain id
   */
  l1ChainId: number;
  /**
   * The L2 chain id
   */
  l2ChainId: number;
  /**
   * Name of the Key for the Aggregator node
   */
  aggregatorKeyId: string;
  /**
   * Name of the Key for the DAC node
   */
  dacNodeKeyId: string;
  /**
   * Name of the Key for the Deployer node
   */
  deployerKeyId: string;
  /**
   * Name of the Key for the Sequencer node
   */
  sequencerKeyId: string;
  /**
   * Map with the information of the deployed contracts
   */
  contracts?: Record<string, ValidiumContractResponse>;
  /**
   * Address of the first block into the Validium
   */
  genesisBlock?: string;
  /**
   * Current phase (state) of the the Validium
   */
  phase: ValidiumResponse.phase;
  /**
   * List of the exposed endpoints by the Validium
   */
  endpoints?: Array<Endpoint>;
  /**
   * List of the corresponding DAC nodes of the Validium
   */
  dacNodes?: Array<DacNodeResponse>;
};
export namespace ValidiumResponse {
  /**
   * Current phase (state) of the the Validium
   */
  export enum phase {
    RUNNING = 'Running',
    PENDING = 'Pending',
  }
}
