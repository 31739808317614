import * as Yup from 'yup';
import { MAX_L1_CHAIN_ID, MAX_L2_CHAIN_ID, MIN_L1_CHAIN_ID, MIN_L2_CHAIN_ID } from '../constants';

// Regexp ----------------------------
const keyIdRegExp = /^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$/;
const privateKeyRegExp = /^0x[0-9a-fA-F]{64}$/;
const baseChainHttpRegExp = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})(:\d{1,5})?(\/[\w.-]*)*\/?$/;
const baseChainWsRegExp = /^(wss?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})(:\d{1,5})?(\/[\w.-]*)*\/?$/;
const validiumNameRegExp = /^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$/;

//  Helpers  ----------------------------
const VALUE_NOT_VALID_ERROR_MESSAGE = 'Value is not valid.';
const generateValidationMessage = (validationMsg?: string) =>
  `${VALUE_NOT_VALID_ERROR_MESSAGE}${validationMsg ? ' ' : ''}${validationMsg}`;

// Messages  ----------------------------
const FIELD_REQUIRED_ERROR_MESSAGE = 'Field is required.';
const VALIDATION_ID_MESSAGE = `It should contain at most 63 characters, contain only lowercase alphanumeric characters or '-', start with an alphabetic character, and end with an alphanumeric character.`;
const VALUE_INTEGER_MESSAGE = 'Value should be integer.';
const INTEGER_MIN_MESSAGE = 'Value should be greater than or equal to';
const INTEGER_MAX_MESSAGE = 'Value should be less than or equal to';
export const KEY_ID_ERROR_VALIDATION_MESSAGE = generateValidationMessage(VALIDATION_ID_MESSAGE);
export const PRIVATE_KEY_ERROR_VALIDATION_MESSAGE = generateValidationMessage(
  `It should be in correct format: '0x' prefix + 64-length hexadecimal string`,
);
export const VALIDIUM_ID_ERROR_VALIDATION_MESSAGE = generateValidationMessage(VALIDATION_ID_MESSAGE);
export const BASE_CHAIN_HTTP_ERROR_VALIDATION_MESSAGE = generateValidationMessage(
  `It should be an URL starting with the 'http://' or 'https://'.`,
);
export const BASE_CHAIN_WS_ERROR_VALIDATION_MESSAGE = generateValidationMessage(
  `It should be an URL starting with the 'ws://' or 'wss://'.`,
);

// Schemas  ----------------------------
export const LoginSchema = Yup.object({
  username: Yup.string().email(VALUE_NOT_VALID_ERROR_MESSAGE).required(FIELD_REQUIRED_ERROR_MESSAGE),
  password: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
});

export const UpdatePasswordSchema = Yup.object({
  password: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
  confirmPassword: Yup.string()
    .required(FIELD_REQUIRED_ERROR_MESSAGE)
    .test('Equals with password field', `Passwords don't match`, (value, context) => {
      const passwordFieldValue = context.from && context.from[0].value.password;

      return passwordFieldValue === value;
    }),
});

export const ResetPasswordSchema = Yup.object({
  email: Yup.string().email(VALUE_NOT_VALID_ERROR_MESSAGE).required(FIELD_REQUIRED_ERROR_MESSAGE),
});

export const KeyCreateSchema = Yup.object({
  id: Yup.string().matches(keyIdRegExp, KEY_ID_ERROR_VALIDATION_MESSAGE).required(FIELD_REQUIRED_ERROR_MESSAGE),
  privateKey: Yup.string().matches(privateKeyRegExp, PRIVATE_KEY_ERROR_VALIDATION_MESSAGE),
});

export const ValidiumCreationSchema = Yup.object({
  validiumId: Yup.string()
    .matches(validiumNameRegExp, VALIDIUM_ID_ERROR_VALIDATION_MESSAGE)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
  baseChainHTTP: Yup.string()
    .matches(baseChainHttpRegExp, BASE_CHAIN_HTTP_ERROR_VALIDATION_MESSAGE)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
  baseChainWS: Yup.string()
    .matches(baseChainWsRegExp, BASE_CHAIN_WS_ERROR_VALIDATION_MESSAGE)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
  l1ChainId: Yup.number()
    .integer(VALUE_INTEGER_MESSAGE)
    .min(MIN_L1_CHAIN_ID, `${INTEGER_MIN_MESSAGE} ${MIN_L1_CHAIN_ID}.`)
    .max(MAX_L1_CHAIN_ID, `${INTEGER_MAX_MESSAGE} ${MAX_L1_CHAIN_ID}.`)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
  l2ChainId: Yup.number()
    .integer(VALUE_INTEGER_MESSAGE)
    .min(MIN_L2_CHAIN_ID, `${INTEGER_MIN_MESSAGE} ${MIN_L1_CHAIN_ID}.`)
    .max(MAX_L2_CHAIN_ID, `${INTEGER_MAX_MESSAGE} ${MAX_L1_CHAIN_ID}.`)
    .required(FIELD_REQUIRED_ERROR_MESSAGE),
  aggregatorKeyId: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
  dacNodeKeyId: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
  deployerKeyId: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
  sequencerKeyId: Yup.string().required(FIELD_REQUIRED_ERROR_MESSAGE),
});
