export enum ErrorType {
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  UNAVAILABLE = 'UNAVAILABLE',
  CLIENT_RESPONSIBLE = 'CLIENT_RESPONSIBLE',
  VALIDATION = 'VALIDATION',
  UNKNOWN = 'UNKNOWN',
  IGNORED = 'IGNORED',
}

export enum QueryKeys {
  VALIDIUMS = 'VALIDIUMS',
  KEYS = 'KEYS',
}

export const MIN_L1_CHAIN_ID = 1;
export const MAX_L1_CHAIN_ID = 2147483647;
export const MIN_L2_CHAIN_ID = 1;
export const MAX_L2_CHAIN_ID = 2147483647;
